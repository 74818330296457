import { Box, Link, Stack, Typography } from '@mui/material'
import ArrowLink from '../components/ArrowLink'
import SectionWrapper from './SectionWrapper'
import apps from '../data/apps'
import { moreAppsLink } from '../data/info'

const Apps = () => {
  return (
    <SectionWrapper
      title={'Cases de sucesso'}
      subtitle={'Apps de estudantes'}
      description={
        <Box sx={{ textAlign: { sm: 'center', xs: 'flex-start' } }}>
          São mais de 350 aplicativos criados na Academy.
          <ArrowLink link={moreAppsLink} sx={{ justifyContent: { sm: 'center', xs: 'flex-start' }, marginTop: 2 }}>
            <Box sx={{ display: { sm: 'inherit', xs: 'none' } }}>
              Conheça mais sobre o processo de desenvolvimento dos nossos apps
            </Box>
            <Box sx={{ display: { sm: 'none', xs: 'inherit' } }}>Conheça mais apps</Box>
          </ArrowLink>
        </Box>
      }
      backgroundColor={'#F2F2F2'}
      pt={{ md: 8, xs: 5.5 }}
      pb={{ md: 6, xs: 5 }}
    >
      <Stack
        direction="row"
        justifyContent={{ md: 'space-evenly', xs: 'inherit' }}
        spacing={{ md: 0, sm: 5, xs: 0 }}
        mt={2}
        pb={2}
        px={{ md: 0, xs: '12.5vw' }}
        sx={{
          overflow: 'scroll',
          width: '100%',
        }}
      >
        {apps.map((app) => (
          <AppTile key={app.name} app={app} />
        ))}
      </Stack>
    </SectionWrapper>
  )
}

const AppTile = ({ app }) => {
  return (
    <Stack sx={{ minWidth: { sm: '204.6px', xs: '75vw' }, width: { sm: '204.6px', xs: '75vw' } }} alignItems="center">
      <Box sx={{ position: 'relative', width: { sm: '204.6px', xs: '60vw' } }}>
        <Box
          component="img"
          src={app.page}
          alt={app.page}
          sx={{
            width: { sm: 'auto', xs: '100%' },
            height: { sm: '444px', xs: 'auto' },
          }}
        />
        <Box
          component="img"
          src={app.icon}
          alt={app.icon}
          sx={{
            width: 'auto',
            height: '96px',
            position: 'absolute',
            bottom: { md: '47px', xs: '-44px' },
            right: { md: '-34px', xs: 0 },
            left: { md: 'inherit', xs: 0 },
            marginX: 'auto',
          }}
        />
      </Box>
      <Link
        color="interaction"
        variant="appName"
        underline="none"
        mt={{ md: 3, xs: 8 }}
        mb={1.5}
        sx={{ textAlign: 'center' }}
        href={app.link}
      >
        {app.name}
      </Link>
      <Typography variant="appDescription" sx={{ textAlign: 'center', width: { sm: '204.6px', xs: '60vw' } }}>
        {app.description}
      </Typography>
    </Stack>
  )
}

export default Apps
