export const numbers = [
  {
    title: '100%',
    description: 'de nossos estudantes desde 2019 publicaram apps na App Store.',
  },
  {
    title: '350+',
    description: 'apps publicados desde 2013 por nossos estudantes.',
  },
  {
    title: '20+',
    description: 'prêmios recebidos por estudantes em Hackathons.',
  },
  {
    title: '15+',
    description: 'startups criadas por estudantes desde o início da Academy.',
  },
]
