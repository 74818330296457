import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import homeImage from '../resources/home.jpg'

const Opening = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        paddingBottom: 2,
        display: 'flex',
        justifyContent: 'center',
        height: { xs: '100vh', md: 'inherit' },
      }}
    >
      <Box
        component="img"
        src={homeImage}
        alt={homeImage}
        sx={{
          width: '100%',
          height: 'auto',
          display: { xs: 'flex', md: 'inherit' },
          objectFit: 'cover',
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: { md: '94px', xs: '50px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography variant="title">Desenvolva uma</Typography>
        <Typography variant="title2">jornada incrível!</Typography>
        <Box
          sx={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
          mt={{ xs: 1, md: 2 }}
        >
          <Typography variant="subtitle" color="#F6C5D6" mt={2}>
            Have a Dream.
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              display: { xs: 'none', md: 'inherit' },
            }}
          >
            &nbsp;
          </Typography>
          <Typography variant="subtitle" color="#F6C5D6" mt={2}>
            Develop a Reality.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Opening
