import { Divider, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import contactImage from '../resources/contact.png'
import twitterIcon from '../resources/icons/social/twitter.png'
import instagramIcon from '../resources/icons/social/instagram.png'
import youtubeIcon from '../resources/icons/social/youtube.png'
import facebookIcon from '../resources/icons/social/facebook.png'
import { twitterLink, instagramLink, youtubeLink, facebookLink, email, students } from '../data/info'

const socials = [
  { icon: instagramIcon, link: instagramLink },
  { icon: twitterIcon, link: twitterLink },
  { icon: youtubeIcon, link: youtubeLink },
  { icon: facebookIcon, link: facebookLink },
]

const Contact = () => {
  const socialLinks = (
    <Stack
      direction="row"
      pt={3.5}
      pb={{ xs: 2, md: 6 }}
      spacing={{ xs: 'none', md: 3 }}
      justifyContent={{ xs: 'space-between', md: 'inherit' }}
      width={{ xs: '100%', md: 'inherit' }}
    >
      {socials.map((social) => (
        <Link href={social.link} key={social.link}>
          <Box
            component="img"
            src={social.icon}
            alt={social.icon}
            sx={{
              height: { xs: '46px', md: '38px' },
              width: 'auto',
            }}
          />
        </Link>
      ))}
    </Stack>
  )

  const foot = (
    <>
      <Box sx={{ textAlign: 'center' }} pt={3.5} pb={{ xs: 2, md: 4.5 }}>
        <Typography variant="address" sx={{ display: 'inline' }}>
          {new Date().getFullYear()} - Todos os direitos reservados
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', maxWidth: '560px', paddingBottom: { xs: 2, md: 0 } }}>
        <Typography variant="address" sx={{ display: 'inline', fontSize: 12, lineHeight: '12px' }}>
          Ilustrações criadas pelas estudantes da Academy:
          <br />
        </Typography>
        {students.map((student, index) => (
          <span key={index}>
            <Link
              color="interaction"
              variant="link"
              sx={{ display: 'inline', fontSize: 12, lineHeight: '12px' }}
              href={student.linkedIn}
            >
              {student.name}
            </Link>
            <Typography variant="address" sx={{ display: 'inline', fontSize: 12, lineHeight: '12px' }}>
              {index === students.length - 1 ? '.' : ', '}
            </Typography>
          </span>
        ))}
      </Box>
    </>
  )

  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      alignItems={{ md: 'flex-end', xs: 'center' }}
      justifyContent="space-between"
      sx={{
        height: { md: '570px', xs: 'inherit' },
        backgroundColor: '#F2F2F2',
        overflowY: 'visible',
      }}
      mt={{ md: 7, xs: 0 }}
      pr={{ md: 4, xs: 0 }}
      pt={{ md: 0, xs: 5.5 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        pl={{ md: '10%', xs: 3 }}
        pr={{ md: 0, xs: 3 }}
        pt={{ md: 0, xs: 3 }}
        sx={{ height: '100%' }}
      >
        <Typography variant="sectionSubtitle">A jornada continua</Typography>
        <Typography variant="sectionTitle">Acompanhe nas redes sociais</Typography>
        {socialLinks}
        <Link variant="email" underline="none" color="black" href={`mailto:${email}`}>
          {email}
        </Link>
        <Divider width="48px" color="#545454" sx={{ marginY: 3 }} />
        <Typography variant="university">Pontifícia Universidade Católica do Paraná (PUCPR)</Typography>
        <Typography variant="address" mt={1}>
          Rua Imaculada Conceição, 1155 - Prado Velho, Curitiba, Paraná
        </Typography>
        <Stack sx={{ display: { xs: 'none', md: 'inherit' } }}>{foot}</Stack>
      </Stack>
      <Box
        component="img"
        src={contactImage}
        alt={contactImage}
        sx={{
          width: { md: '36%', xs: '100%' },
          height: { md: 'auto', xs: 'auto' },
          maxWidth: '530px',
        }}
      />
      <Stack sx={{ display: { md: 'none', xs: 'inherit' } }}>{foot}</Stack>
    </Stack>
  )
}

export default Contact
