import { Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ApplyLink from '../components/ApplyLink'
import { appleRefLink, application } from '../data/info'
import SectionWrapper from './SectionWrapper'

const Introduction = () => {
  return (
    <SectionWrapper
      title={'Apple Developer Academy PUCPR'}
      subtitle={'Para quem tem paixão por aprender e mudar o mundo'}
      pt={6}
      pb={{ md: 6, xs: 2.5 }}
    >
      <Box sx={{ width: { md: '66%', xs: 'inherit' }, marginBottom: { md: 4, xs: 0 } }} pt={-6}>
        <Box sx={{ marginX: { md: 0, xs: 3 } }}>
          <Typography variant="sectionText" mt={3}>
            Apple Developer Academy é um curso de extensão para estudantes aprenderem a desenvolver apps em tecnologias
            Apple (iOS, iPadOS, watchOS e tvOS).
          </Typography>
          <Typography variant="sectionText" mt={1}>
            Esta parceria entre{' '}
            <Link color="interaction" variant="link" sx={{ display: 'inline' }} href={appleRefLink}>
              Apple™ e PUCPR
            </Link>{' '}
            (Curitiba, PR) teve início em junho de 2013, e por aqui já passaram 381 estudantes. O processo seletivo para
            a 7ª turma irá iniciar em 02 de setembro de 2024.{' '}
            <Link href="#Processo" color="interaction" variant="link" sx={{ display: 'inline' }}>
              Como participar?
            </Link>
          </Typography>
          <Typography variant="sectionText" mt={{ xs: 1, md: 4 }} mb={{ xs: 2, md: 4 }}>
            Inicialmente, o curso voltava-se para formação de engenheiros de sistemas e computação. Ao longo dos anos,
            também foram abertas vagas para designers. Hoje, recebemos estudantes dos mais diferentes cursos e
            formações, que gostam de aprender e criar tecnologias.
          </Typography>
          {application.open && <ApplyLink>Processo seletivo aberto</ApplyLink>}
          <Typography variant="sectionTitle2" sx={{ marginTop: { lg: 10, xs: 8 }, textAlign: 'center' }}>
            Tudo começa com um desafio
          </Typography>
        </Box>
        <Stack
          direction={{ lg: 'row-reverse', xs: 'column' }}
          spacing={{ lg: 12, xs: 3 }}
          mt={{ lg: 6, xs: 1.5 }}
          alignItems="center"
        >
          <Stack
            direction="column"
            sx={{
              marginX: { md: 0, xs: 3 },
              flexShrink: 2,
            }}
          >
            <Typography variant="sectionText">Na Apple Developer Academy, todo mundo é desenvolvedor!</Typography>
            <Typography variant="sectionText" mt={1}>
              Não importa se você se dá melhor com programação, design, negócios ou habilidades interpessoais, estamos
              aqui para aprender e crescer em conjunto.
            </Typography>
          </Stack>
          <Box sx={{ width: { lg: '54%', xs: '100%', flexShrink: 0 } }}>
            <Box
              sx={{
                width: '100%',
                height: 0,
                overflow: 'hidden',
                paddingBottom: '56.25%',
                position: 'relative',
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/tYU8npYmJjA"
                title="História de um Desafio"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  left: 0,
                  top: 0,
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    </SectionWrapper>
  )
}
export default Introduction
