import { Link } from '@mui/material'
import { applicationLink } from '../data/info'

const ApplyLink = ({ isNav = false, sx, onClick, children, href = applicationLink }) => {
  return (
    <Link
      variant="applyLink"
      color="white"
      underline="none"
      href={href}
      onClick={onClick}
      sx={{
        ...sx,
        backgroundColor: 'interaction',
        paddingY: { xs: 1, md: isNav ? 1.5 : 2 },
        paddingX: { xs: 2.5, md: isNav ? 3 : 4 },
        borderRadius: 8,
        fontSize: isNav ? 16 : 20,
        textAlign: 'center',
      }}
    >
      {children}
    </Link>
  )
}

export default ApplyLink
