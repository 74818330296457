import binder from '../resources/mentors/binder.jpg'
import ana from '../resources/mentors/ana.jpg'
import ale from '../resources/mentors/ale.jpg'
import barbs from '../resources/mentors/barbs.jpg'
import broona from '../resources/mentors/broona.jpg'
import gonzatto from '../resources/mentors/gonzatto.jpg'
import maicris from '../resources/mentors/maicris.jpg'
import mark from '../resources/mentors/mark.jpg'
import veloso from '../resources/mentors/veloso.jpg'
import bruno from '../resources/mentors/bruno.jpg'


const mentors = [
  {
    name: 'Fabio Binder',
    description: 'Coordenador ⭐️ do projeto, Mentor de Programação',
    image: binder,
  },
  {
    name: 'Maicris Fernandes',
    description: 'Mentor de Programação',
    image: maicris,
  },
  {
    name: 'Mark Joselli',
    description: 'Mentor de Programação, Responsável pelo Academy Masters',
    image: mark,
  },
  {
    name: 'Rodrigo Gonzatto',
    description: 'Mentor de Design/UX',
    image: gonzatto,
  },
  {
    name: 'Luiz Veloso',
    description: 'Mentor de Negócios e Programação',
    image: veloso,
  },
  {
    name: 'Alessandra Souza',
    description: 'Mentora Jr. de Programação',
    image: ale,
  },
  {
    name: 'Barbra Eliza',
    description: 'Mentora Jr. de Design/UX',
    image: barbs,
  },
  {
    name: 'Bruno Thuma',
    description: 'Mentor Jr. de Programação',
    image: bruno,
  },
  {
    name: 'Broona Oliveira',
    description: 'Mentora Jr. de Design/UX',
    image: broona,
  },
  {
    name: 'Ana C Costa',
    description: 'Mentora Jr. de Design/UX',
    image: ana,
  },
]

export default mentors
