import { ArrowForward } from '@mui/icons-material'
import { Link } from '@mui/material'

const ArrowLink = ({ children, link, sx }) => (
  <Link
    variant="link"
    color="interaction"
    underline="none"
    href={link}
    sx={{
      ...sx,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    {children}
    <ArrowForward sx={{ marginLeft: 0.5 }} />
  </Link>
)

export default ArrowLink
