import { Box } from '@mui/material'
import ResponsiveAppBar from './AppBar'
import Home from './1-Home'
import About from './2-About'
import Apply from './3-Apply'
import Topics from './4-Topics'
import Apps from './5-Apps'
import Mentors from './6-Mentors'
import Numbers from './7-Numbers'
import Contact from './9-Contact'

const Main = () => (
  <Box>
    <ResponsiveAppBar />
    <section id="Home">
      <Home />
    </section>
    <section id="Sobre">
      <About />
    </section>
    <section id="Processo">
      <Apply />
      <Topics />
    </section>
    <section id="Apps">
      <Apps />
    </section>
    <section id="Mentoria">
      <Mentors />
    </section>
    <Numbers />
    <section id="Contato">
      <Contact />
    </section>
  </Box>
)

export default Main
