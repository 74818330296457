import jamesFull from '../resources/apps/james-full.png'
import jamesIcon from '../resources/apps/james-icon.png'
import veeverFull from '../resources/apps/veever-full.png'
import veeverIcon from '../resources/apps/veever-icon.png'
import superpadsFull from '../resources/apps/superpads-full.png'
import superpadsIcon from '../resources/apps/superpads-icon.png'
import jumpoFull from '../resources/apps/jumpo-full.png'
import jumpoIcon from '../resources/apps/jumpo-icon.png'

const apps = [
  {
    name: 'James Delivery',
    description:
      'Plataforma de entregas que teve 3 estudantes como sócios. Parte do projeto iniciou durante um Macro Challenge e chegou a operar em 35+ cidades.',
    page: jamesFull,
    icon: jamesIcon,
    link: 'https://www.jamesdelivery.com.br/',
  },
  {
    name: 'Veever',
    description:
      'App para locomoção de pessoas com deficiência visual, por microlocalização. Usado na acessibilidade de eventos, como Rock in Rio 2019.',
    page: veeverFull,
    icon: veeverIcon,
    link: 'https://veever.global/',
  },
  {
    name: 'SuperPads',
    description:
      'Nasceu a partir da ideia de um estudante apaixonado por música. Um dos maiores apps da categoria, com 120+ milhões de downloads.',
    page: superpadsFull,
    icon: superpadsIcon,
    link: 'https://apps.apple.com/br/app/super-pads-seja-um-dj/id1196953001',
  },
  {
    name: 'jump-O',
    description:
      'Jogo com arte minimalista criado por dois alunos em um Mini Challenge da Academy. Vencedor do prêmio Apple Design Awards em 2015.',
    page: jumpoFull,
    icon: jumpoIcon,
    link: 'https://apps.apple.com/br/app/jump-o/id796270242',
  },
]

export default apps
