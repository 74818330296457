import { createTheme } from '@mui/material/styles'
import createBreakpoints from '@mui/system/createTheme/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createTheme({
  spacing: 8,
  typography: {
    navlink: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: '5%',
      textTransform: 'none',
      textAlign: 'center',
      display: 'inline-block',

      '&:hover': {
        cursor: 'pointer',
        color: '#9F0538',
      },
    },
    applyLink: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: '5%',
      textTransform: 'none',
      textAlign: 'center',
      display: 'inline-block',

      '&:hover': {
        cursor: 'pointer',
        background: '#9F0538',
      },

      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    title: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 60,
      lineHeight: '70px',
      textAlign: 'center',
      display: 'inline-block',
      color: '#FEF5F8',

      [breakpoints.down('md')]: {
        fontSize: 34,
        lineHeight: '40px',
      },
    },
    title2: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 66,
      lineHeight: '70px',
      textAlign: 'center',
      display: 'inline-block',
      color: '#FEF5F8',

      [breakpoints.down('md')]: {
        fontSize: 38,
        lineHeight: '40px',
      },
    },
    subtitle: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: '38px',
      textAlign: 'center',
      letterSpacing: '3%',
      display: 'inline-block',

      [breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '12px',
      },
    },
    mentorName: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '26px',
      display: 'inline-block',

      [breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    mentorDescription: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      display: 'inline-block',
      color: '#545454',

      [breakpoints.down('md')]: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    sectionTitle: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '46px',
      lineHeight: '55px',
      textAlign: 'center',

      [breakpoints.down('md')]: {
        fontSize: 32,
        lineHeight: '38px',
      },
    },
    sectionSubtitle: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '35px',
      textAlign: 'center',

      [breakpoints.down('md')]: {
        fontSize: 16,
        lineHeight: '22px',
      },
    },
    sectionText: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '30px',
      color: '#545454',
      display: 'inline-block',

      [breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    link: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '30px',
      display: 'block',

      '&:hover': {
        cursor: 'pointer',
        color: '#9F0538',
      },

      [breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    sectionTitle2: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
      display: 'block',

      [breakpoints.down('md')]: {
        fontSize: 16,
        lineHeight: '19px',
      },
    },
    appName: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '30px',
      display: 'block',

      '&:hover': {
        cursor: 'pointer',
        color: '#9F0538',
      },

      [breakpoints.down('md')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    appDescription: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '19px',
      color: '#545454',
    },
    email: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
    },
    university: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      color: '#545454',
      textAlign: 'center',
    },
    address: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#545454',
      textAlign: 'center',
    },
    statistic: {
      fontFamily: ['Helvetica Neue','Arial','sans-serif'],
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '43px',
      display: 'block',

      [breakpoints.down('md')]: {
        fontSize: 32,
        lineHeight: '38px',
      },
    },
  },
  palette: {
    interaction: '#219B85',
    primary: {
      main: '#9F0538',
    },
  },
})

export default theme
