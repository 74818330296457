import { Box, Link, Stack, Typography } from '@mui/material'
import ArrowLink from '../components/ArrowLink'
import { appleNewsLink, mediumLink } from '../data/info'
import { numbers } from '../data/numbers'

const Numbers = () => {
  return (
    <Stack
      direction={{ lg: 'row', xs: 'column' }}
      alignItems="center"
      justifyContent="space-between"
      spacing={{ lg: 0, xs: 3 }}
      mt={{ xs: 5, md: 14 }}
      mb={{ xs: 5, md: 16 }}
      mx={{ lg: '16.5%', md: 0, xs: 3 }}
    >
      <Stack
        alignItems={{ lg: 'flex-start', xs: 'center' }}
        sx={{
          width: { lg: '46%', md: '66%', xs: '100%' },
        }}
      >
        <Typography variant="sectionSubtitle" sx={{ textAlign: 'start' }}>
          Algumas de nossas conquistas
        </Typography>
        <Typography variant="sectionTitle" sx={{ textAlign: { md: 'start', xs: 'center' } }}>
          Da Academy para o mundo
        </Typography>
        <div>
          <Typography variant="sectionText" mt={3} mb={2}>
            Mais que um curso, na Academy desenvolvemos tecnologias{' '}
            <Link color="interaction" variant="link" sx={{ display: 'inline' }} href={appleNewsLink}>
              para o mundo real
            </Link>
            . Reunimos alguns dos números que mostram destaques da formação de nossos estudantes.
          </Typography>
          <ArrowLink link={mediumLink}>Mais destaques no nosso Medium</ArrowLink>
        </div>
      </Stack>
      <Box
        sx={{
          width: { lg: '50%', md: '66%', xs: '100%' },
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        {numbers.map((number) => (
          <NumberTile key={number.title} number={number} />
        ))}
      </Box>
    </Stack>
  )
}

const NumberTile = (number) => {
  const size = { lg: '13vw', md: '20vw', xs: '40vw' }
  const minSize = { md: '170px', xs: '150px' }
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        borderRadius: '8px',
        backgroundColor: '#F2F2F2',
        width: size,
        height: size,
        minWidth: minSize,
        minHeight: minSize,
      }}
      px={2}
      py={3}
      m={{ md: 1.5, xs: 0.5 }}
    >
      <Typography variant="statistic">{number.number.title}</Typography>
      <Typography variant="mentorDescription" sx={{ textAlign: 'center' }}>
        {number.number.description}
      </Typography>
    </Stack>
  )
}

export default Numbers
