import { Box, Typography } from '@mui/material'
import mentors from '../data/mentors'
import SectionWrapper from './SectionWrapper'

const Mentors = () => {
  const getMentor = (name) => {
    return mentors.find((e) => e.name === name)
  }
  const ColumnMentors = ({ mentorData, orientation }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {mentorData.map((mentor, index) => (
        <MentorTile key={index} mentor={getMentor(mentor)} orientation={orientation[index]} />
      ))}
    </Box>
  );

  const RowMentors = ({ mentorData, orientation }) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {mentorData.map((mentor, index) => (
        <MentorTile key={index} mentor={getMentor(mentor)} orientation={orientation[index]} />
      ))}
    </Box>
  );

  return (
    <SectionWrapper
      title={'Equipe de mentoria'}
      subtitle={'Auxílio à aprendizagem'}
      description={`São ${mentors.length} profissionais capacitados para auxiliar os estudantes no processo de aprendizado. Na Academy, temos mentoria nas áreas de Programação, Design/UX e Negócios.`}
      backgroundColor={'#F2F2F2'}
      pt={{ md: 6, xs: 3 }}
      pb={{ md: 6, xs: 4.75 }}
    >
      <Box sx={{ display: { lg: 'flex', xs: 'none' }, justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <MentorTile mentor={getMentor('Barbra Eliza')} orientation="horizontal" />
          <RowMentors mentorData={['Maicris Fernandes', 'Alessandra Souza']} orientation={['vertical', 'vertical']} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <RowMentors mentorData={['Fabio Binder', 'Bruno Thuma']} orientation={['vertical', 'vertical']} />
              <MentorTile mentor={getMentor('Ana C Costa')} orientation="horizontal" />
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ColumnMentors mentorData={['Broona Oliveira', 'Luiz Veloso']} orientation={['horizontal', 'horizontal']} />
                <MentorTile mentor={getMentor('Rodrigo Gonzatto')} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <MentorTile mentor={getMentor('Mark Joselli')} orientation="horizontal" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { lg: 'none', xs: 'flex' },
          flexDirection: 'row',
          overflow: 'scroll',
          paddingX: 2,
          paddingBottom: 2,
          width: '100vw',
        }}
      >
        {mentors.map((mentor) => (
          <MentorTile key={mentor.name} mentor={mentor} />
        ))}
      </Box>
    </SectionWrapper>
  )
}

export default Mentors

const MentorTile = ({ mentor, orientation }) => {
  const margin = 1.5
  const isHorizontal = orientation === 'horizontal'
  const dimensions = isHorizontal
    ? {
      width: { md: 320 + margin * 8 * 2, xs: 125 },
      height: { md: 160, xs: 250 },
    }
    : {
      width: { md: 160, xs: 125 },
      height: { md: 320 + margin * 8 * 2, xs: 250 },
    }

  return (
    <Box
      sx={{
        ...dimensions,
        minWidth: dimensions.width,
        minHeight: dimensions.height,
        backgroundColor: 'white',
        borderRadius: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: isHorizontal ? 'row' : 'column',
        margin: { md: margin, xs: 1 },
      }}
    >
      <Box
        component="img"
        src={mentor.image}
        alt={mentor.image}
        sx={{
          minWidth: { md: isHorizontal ? 160 : '100%', xs: 125 },
          minHeight: { md: isHorizontal ? '100%' : 160, xs: 125 },
          objectFit: 'cover',
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { md: 1.25, xs: 1 },
          marginX: { md: isHorizontal ? 0.75 : 0.5, xs: 0 },
        }}
      >
        <Typography variant="mentorName">{mentor.name}</Typography>
        <Typography variant="mentorDescription" pt={1}>
          {mentor.description}
        </Typography>
      </Box>
    </Box>
  )
}
