import { Link, Stack, Typography } from '@mui/material'
import SectionWrapper from './SectionWrapper'
import { ReactComponent as CodeIcon } from '../resources/icons/code.svg'
import { ReactComponent as DesignIcon } from '../resources/icons/design.svg'
import { ReactComponent as BusinessIcon } from '../resources/icons/business.svg'
import { ReactComponent as MetaIcon } from '../resources/icons/meta.svg'
import { cblLink } from '../data/info'

const topics = [
  {
    icon: <CodeIcon />,
    text: 'Programação: trabalhamos com a linguagem Swift, aprendendo desde lógica e algoritmos até programação para backend.',
  },
  {
    icon: <DesignIcon />,
    text: 'Design: trabalhamos com composição e prototipação de interfaces, design de interação e UX.',
  },
  {
    icon: <BusinessIcon />,
    text: 'Negócios: revisamos e aprendemos conceitos de marketing, divulgação e empreendedorismo.',
  },
  {
    icon: <MetaIcon />,
    text: 'Habilidades interpessoais: abordamos assuntos como: criatividade, feedback, comunicação, apresentação e colaboração.',
  },
]

const Topic = ({ icon, text }) => {
  return (
    <Stack direction={{ md: 'row', xs: 'column' }} alignItems={{ md: 'center', xs: 'flex-start' }}>
      <div style={{ width: '80px', height: '36px', flexShrink: 0 }}>{icon}</div>
      <Typography variant="sectionText" pt={{ md: 0, xs: 1 }}>
        {text}
      </Typography>
    </Stack>
  )
}

const Topics = () => {
  return (
    <SectionWrapper
      title={'O que se aprende na Academy?'}
      subtitle={'Processo de ensino'}
      description={
        <>
          Temos um ambiente educacional estimulante para aprendizado de todo o processo de desenvolvimento de apps, da
          concepção até a publicação. Aqui, todo mundo desenvolve habilidades em programação, design, negócios e
          habilidades interpessoais.
          <div style={{ height: 8 }} />
          Nossa pedagogia segue o{' '}
          <Link color="interaction" variant="link" sx={{ display: 'inline' }} href={cblLink}>
            Challenge Based Learning (CBL)
          </Link>
          , metodologia ativa baseada em desafios, no aprendizado para a vida e com foco na autonomia e no gosto por
          aprender. Aqui, cada estudante é responsável pela sua própria jornada de aprendizado e a Academy ajuda a
          explorar seu potencial.
        </>
      }
      pb={{ md: 12, xs: 5 }}
      pt={{ md: 0, xs: 8 }}
    >
      <Stack spacing={{ md: 6, xs: 3 }} sx={{ width: { md: '50%', xs: 'inherit' }, paddingX: { md: 0, xs: 3 } }} py={2}>
        {topics.map((topic) => (
          <Topic key={topic.text} {...topic} />
        ))}
      </Stack>
    </SectionWrapper>
  )
}

export default Topics
