import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const SectionWrapper = ({ children, title, subtitle, backgroundColor, description, pt = 6, pb = 6 }) => (
  <Box sx={{ backgroundColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }} pt={pt} pb={pb}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: { md: 0, xs: 3 },
        width: '100%',
      }}
    >
      {title && (
        <Typography variant="sectionSubtitle" px={6}>
          {subtitle}
        </Typography>
      )}
      {subtitle && <Typography variant="sectionTitle">{title}</Typography>}
      {description && (
        <Typography variant="sectionText" sx={{ paddingY: { md: 3, xs: 1.5 }, width: { md: '66%', xs: 'inherit' } }}>
          {description}
        </Typography>
      )}
    </Box>
    {children}
  </Box>
)

export default SectionWrapper
